import React from 'react'
import { format } from 'date-fns'

import { groupByObjectProperty, getDateRangeArr } from '../lib/helpers'

import SearchResultLink from '../mui-components/SearchResultLink/SearchResultLink'
import NonExpansiblePanel from '../mui-components/NonExpansiblePanel/NonExpansiblePanel'

// Search component
const Search = props => {
  // First group results by different types
  const groupedResults = groupByObjectProperty(props.results, 'type')

  // Prepare static header
  let content = [
    <NonExpansiblePanel
      title={
        props.searchQuery ? 'Search results for: "' + props.searchQuery + '"' : 'No results found'
      }
      key="search-title"
    />
  ]

  // Prepare results boxes
  Object.keys(groupedResults).map((groupProp, index) => {
    let title = ''

    // Get proper title
    switch (groupProp) {
      case 'sanction-type':
        title = 'Sanction Types'
        break
      case 'cases':
        title = 'Cases'
        break
      case 'episode':
        title = 'Episodes'
        break
      case 'checklist':
        title = 'Checklist'
        break
      default:
        title = 'Other'
        break
    }

    let searchBoxChildren = []

    // Get all links for the type
    groupedResults[groupProp].forEach((item, index) => {
      let itemDates = null
      let itemTitle =
        item.type === 'cases' ? (item.references[0] ? item.references[0] : item.title) : item.title

      if (item.type === 'cases' || item.type === 'episode') {
        const startDate = item.date[0] ? format(new Date(item.date[0]), 'MMM-YYYY') : null
        const endDate = item.date[1] ? format(new Date(item.date[1]), 'MMM-YYYY') : null
        itemDates = getDateRangeArr(startDate, endDate)
      }

      searchBoxChildren.push(
        <SearchResultLink to={item.path} title={itemTitle} subtitle={itemDates} key={index} />
      )
    })

    // Create Search panel with links
    content.push(
      <NonExpansiblePanel
        title={title}
        subtitle={groupedResults[groupProp].length + ' results'}
        highlight
        key={groupProp}
      >
        {searchBoxChildren}
      </NonExpansiblePanel>
    )
  })

  return content
}

export default Search
